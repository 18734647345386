import { DeveloperModel, ResponseError } from '../../apis';
import { AppThunkAction } from '../index';
import { ApplicationAction, setActionRequestingAction, setActionResponseErrorAction } from './index';
import { SHOW_DEVELOPER_SIGN_UP } from '../constants';
import * as api from '../../apis';

export interface ShowDeveloperSingUpAction {
    type: SHOW_DEVELOPER_SIGN_UP;
    payload: {
        data: boolean;
    };
}

export const showDeveloperSignUpAction = (showForm: boolean): ShowDeveloperSingUpAction => ({
    type: SHOW_DEVELOPER_SIGN_UP,
    payload: {
        data: showForm,
    },
});

export type DeveloperAction = ShowDeveloperSingUpAction;

export function addDeveloperUserAction(developer: DeveloperModel): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('add_developer_user', true));

        try {
            await api.createDeveloperUser(developer);
            await dispatch(setActionRequestingAction('add_developer_user', false));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('add_developer_user', e));
            }
            await dispatch(setActionRequestingAction('add_developer_user', false));
        }
    };
}

export function setDeveloperSignUpFormAction(data: boolean): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('set_developer_sign_up', true));
        try {
            await dispatch(showDeveloperSignUpAction(data));
            await dispatch(setActionRequestingAction('set_developer_sign_up', false));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('set_developer_sign_up', e));
            }
            await dispatch(setActionRequestingAction('set_developer_sign_up', false));
        }
    };
}
