import * as React from 'react';
import {
    fetchChargesAction,
    FetchChargesActionOptions,
    fetchCreditsAction,
    fetchPromotionsAction,
} from '../store/actions';
import { history } from '../index';
import Layout from '../components/Layout';
import { RouteComponentProps } from 'react-router';
import { CHARGE_STATUS, ChargeModel, downloadChargesReport } from '../apis';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import ChargesTable from '../components/charge/ChargesTable';
import selectors from '../store/selectors';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import { Button, CardBody, Input, InputGroup, InputGroupAddon, InputGroupText, TabContent, TabPane } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { debounce } from 'lodash';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import querystring from 'querystring';
import ListFilters from '../components/ListFilters';
import { DateTime } from 'luxon';
import Dropdown from 'reactstrap/lib/Dropdown';
import { ApplicationState } from '../store';
const searchIcon = '/Search-Icon.svg';
const reportIcon = '/report.svg';

interface DispatchProps {
    fetchChargesAction: typeof fetchChargesAction;
    fetchCreditsAction: typeof fetchCreditsAction;
    fetchPromotionsAction: typeof fetchPromotionsAction;
}

interface StateProps {
    charges: ChargeModel[];
    chargesTotal: number;
    chargesSkipped: number;
    chargesLimit: number;
}

type OwnProps = RouteComponentProps<{
    transactionId: string;
}>;

type Props = DispatchProps & OwnProps & StateProps;

interface State {
    paymentSourceId?: string;
    from?: DateTime;
    to?: DateTime;
    status: CHARGE_STATUS[];
    open: boolean;
    search: string;
    activeTab: number;
}

class Transactions extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            paymentSourceId: undefined,
            from: undefined,
            to: undefined,
            status: [],
            open: false,
            search: '',
            activeTab: 1,
        };
    }

    componentDidMount = async () => {
        const search = querystring.parse(this.props.history.location.search.replace('?', ''));

        if (search.search) {
            await this.setState({
                search: search.search as string,
            });
        }

        if (search.from) {
            await this.setState({
                activeTab: 2,
                from: DateTime.fromISO(search.from as string),
            });
        }
        if (search.to) {
            await this.setState({
                activeTab: 2,
                to: DateTime.fromISO(search.to as string),
            });
        }
        if (search.status) {
            await this.setState({
                activeTab: 2,
                status:
                    typeof search.status === 'string'
                        ? (search.status.split(',') as CHARGE_STATUS[])
                        : (search.status as CHARGE_STATUS[]),
            });
        }

        if (search.paymentSourceId) {
            await this.setState({
                activeTab: 2,
                paymentSourceId: search.paymentSourceId as string,
            });
        }

        await this.fetchCharges();
        this.props.fetchPromotionsAction();
    };

    updateUrl = () => {
        const { from, to, status, search, paymentSourceId } = this.state;

        const query = querystring.stringify({
            from: from ? selectors.format.formatDateIso(from) : undefined,
            to: to ? selectors.format.formatDateIso(to) : undefined,
            paymentSourceId: paymentSourceId || undefined,
            status: status || undefined,
            search: search || undefined,
        });

        if (from || to || status.length > 0 || search || paymentSourceId) {
            this.props.history.push({
                pathname: this.props.history.location.pathname,
                search: `?${query}`,
            });
        }
    };

    handleDateFromChange = async (date: DateTime | null) => {
        await this.setState({ from: date || undefined });
        this.fetchChargesDebounced();
    };

    handleDateToChange = (date: DateTime | null) => {
        this.setState({ to: date || undefined });
        this.fetchChargesDebounced();
    };

    handleStatusesChange = (statuses: CHARGE_STATUS[]) => {
        this.setState({ status: statuses });
        this.fetchChargesDebounced();
    };

    onRowClicked = (data: ChargeModel) => {
        history.push(selectors.global.transactionRoute(data.id));
    };

    fetchCharges = async (skip?: number) => {
        const { status, from, to, search, activeTab, paymentSourceId } = this.state;
        this.updateUrl();

        let options: FetchChargesActionOptions = {
            skip,
        };
        if (activeTab === 1) {
            options = {
                ...options,
                search,
            };
        } else {
            options = {
                ...options,
                status: status,
                from: selectors.format.formatDateIso(from) || undefined,
                to: selectors.format.formatDateIso(to) || undefined,
                paymentSourceId: paymentSourceId,
            };
        }

        await this.props.fetchChargesAction(options);
        this.props.fetchCreditsAction({ originatedFromChargeId: this.props.charges.map((c) => c.id) });
    };

    fetchChargesDebounced = debounce(this.fetchCharges, 1000);

    downloadChargesReport = () => {
        const { status, from, to } = this.state;

        downloadChargesReport({
            status: status,
            from: selectors.format.formatDateIso(from) || undefined,
            to: selectors.format.formatDateIso(to) || undefined,
        });
    };

    onSearchChange = async (search: string) => {
        await this.setState({ search: search });
        this.fetchChargesDebounced();
    };

    onPaymentSourceChange = async (paymentSourceId: string) => {
        await this.setState({ paymentSourceId });
        this.fetchChargesDebounced();
    };

    toggleTab = (activeTab: number) => {
        this.setState({ activeTab }, () => this.fetchCharges());
    };

    renderSearch = () => {
        return (
            <div className="col-md-6 col-12">
                <InputGroup>
                    <Input
                        bsSize={'lg'}
                        value={this.state.search}
                        onChange={(e) => this.onSearchChange(e.target.value)}
                        placeholder={'Search by External ID'}
                    />
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <img src={searchIcon} width={'21px'} />
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
            </div>
        );
    };

    renderAdvancedSearch = () => {
        return (
            <CardBody>
                <ListFilters
                    chargeStatuses={this.state.status}
                    to={this.state.to}
                    from={this.state.from}
                    paymentSourceId={this.state.paymentSourceId}
                    onPaymentSourceChange={this.onPaymentSourceChange}
                    onChargeStatusChange={this.handleStatusesChange}
                    onDateFromChange={this.handleDateFromChange}
                    onDateToChange={this.handleDateToChange}
                />
            </CardBody>
        );
    };

    render() {
        const { activeTab, open } = this.state;
        return (
            <Layout>
                <div className="mb-4">
                    <div className="d-flex">
                        <h1 className="mt-0">Transactions</h1>
                        <div className="ml-auto d-flex flex-column justify-content-center">
                            <Dropdown isOpen={open} toggle={() => this.setState({ open: !open })} direction="down">
                                <DropdownToggle
                                    size="sm"
                                    color="secondary"
                                    outline
                                    caret={false}
                                    className="report-button"
                                >
                                    <FontAwesomeIcon icon={faEllipsisH} />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem
                                        onClick={() => this.downloadChargesReport()}
                                        className="d-flex justify-content-between align-items-center"
                                    >
                                        <div className="mr-2">Download report</div>
                                        <div>
                                            <img src={reportIcon} width="19px" height="19px" />
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>

                    <div>
                        <div className="font-weight-semi-bold mb-4">
                            Search transactions by id, date range or status
                        </div>
                    </div>

                    <div className="mb-4">
                        <div className=" border-bottom-gray-sm mb-4">
                            <div className="d-flex mt-3 col-12 col-md-6 mb-4">
                                <div>
                                    <Button
                                        color="none"
                                        className={`mr-4 search-btn ${
                                            activeTab === 1 ? 'btn-primary-2' : 'text-primary-2'
                                        }`}
                                        onClick={() => this.toggleTab(1)}
                                    >
                                        Search
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        color="none"
                                        className={`mr-4 search-btn  ${
                                            activeTab === 2 ? 'btn-primary-2' : 'text-primary-2'
                                        }`}
                                        onClick={() => this.toggleTab(2)}
                                    >
                                        Advanced - Filter
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <TabContent activeTab={activeTab}>
                            <TabPane className="fade-in" tabId={1}>
                                {this.renderSearch()}
                            </TabPane>
                            <TabPane className="fade-in" tabId={2}>
                                {this.renderAdvancedSearch()}
                            </TabPane>
                        </TabContent>
                    </div>
                    <ChargesTable onRowClick={this.onRowClicked} onGoTo={(page, skip) => this.fetchCharges(skip)} />
                </div>
            </Layout>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchChargesAction,
            fetchCreditsAction,
            fetchPromotionsAction,
        },
        dispatch,
    );

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    charges: state.charge.list.items,
    chargesSkipped: state.charge.list.skipped,
    chargesTotal: state.charge.list.total,
    chargesLimit: state.charge.list.limit,
});

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
