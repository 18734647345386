import * as React from 'react';
import { Button, ButtonGroup, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import Select, { OptionTypeBase } from 'react-select';
import DatePicker from './DatePicker';
import { CHARGE_STATUS } from '../apis';
import { map, xor } from 'lodash';
import selectors from '../store/selectors';
import { DateTime } from 'luxon';
const searchIcon = '/Search-Icon.svg';
const calendarIcon = '/Calander---Grey.svg';

export interface Props {
    from?: DateTime;
    to?: DateTime;
    paymentSourceId?: string;
    onPaymentSourceChange?: (search: string) => void;
    chargeStatuses?: CHARGE_STATUS[];
    onDateFromChange: (date: DateTime | null) => void;
    onDateToChange: (date: DateTime | null) => void;
    onChargeStatusChange?: (statuses: CHARGE_STATUS[]) => void;
}

interface StatusOptionType extends OptionTypeBase {
    value: CHARGE_STATUS;
    label: string;
}

const fromChargeStatusToOption = (status: CHARGE_STATUS): StatusOptionType => ({
    label: selectors.charge.formatStatus(status),
    value: status,
});

export default class ListFilters extends React.Component<Props> {
    notCaptured = (): CHARGE_STATUS[] => [
        CHARGE_STATUS.AUTHORIZED,
        CHARGE_STATUS.ATTEMPTING_COLLECTION,
        CHARGE_STATUS.STOPPED_COLLECTION,
        CHARGE_STATUS.CANCELED,
        CHARGE_STATUS.CAPTURE_FAILED,
        CHARGE_STATUS.CREATED,
        CHARGE_STATUS.DISPUTED,
        CHARGE_STATUS.REFUND_PENDING,
        CHARGE_STATUS.REFUNDED,
    ];

    onStatusChange = (statuses: CHARGE_STATUS[]) => {
        if (this.props.onChargeStatusChange) {
            this.props.onChargeStatusChange(statuses);
        }
    };

    onPaymentSourceChange = (search: string) => {
        if (this.props.onPaymentSourceChange) {
            this.props.onPaymentSourceChange(search);
        }
    };

    render() {
        const { from, to, paymentSourceId, chargeStatuses } = this.props;
        const dateIcon = <img src={calendarIcon} width={'21px'} />;
        const noChargeCaptured = xor(chargeStatuses, [CHARGE_STATUS.CAPTURED]).length === 0;
        const captured = xor(chargeStatuses, this.notCaptured()).length === 0;
        const noStatus = chargeStatuses && chargeStatuses.length === 0;

        return (
            <>
                <Row>
                    <Col md={6} className="mb-3">
                        <DatePicker
                            selected={from?.toJSDate()}
                            onChange={(date) => this.props.onDateFromChange(date ? DateTime.fromJSDate(date) : null)}
                            maxDate={to?.toJSDate() || DateTime.now().toJSDate()}
                            label={dateIcon}
                            placeholderText={'FROM - Select from date'}
                            className="form-control-lg"
                        />
                    </Col>
                    <Col md={6} className="mb-3">
                        <DatePicker
                            selected={to?.toJSDate()}
                            onChange={(date) => this.props.onDateToChange(date ? DateTime.fromJSDate(date) : null)}
                            minDate={from?.toJSDate()}
                            maxDate={DateTime.now().toJSDate()}
                            label={dateIcon}
                            placeholderText={'TO - Select to date'}
                            className="form-control-lg"
                        />
                    </Col>
                    {this.props.onChargeStatusChange && this.props.chargeStatuses && (
                        <>
                            <Col md={6} className="mb-3">
                                <ButtonGroup className="w-100 list-btn">
                                    <Button
                                        onClick={() => this.onStatusChange([CHARGE_STATUS.CAPTURED])}
                                        className={`${!captured ? 'btn-dark' : 'btn-gray-150'}`}
                                        size="lg"
                                    >
                                        Captured
                                    </Button>
                                    <Button
                                        onClick={() => this.onStatusChange(this.notCaptured())}
                                        className={`${!noChargeCaptured ? 'btn-dark' : 'btn-gray-150'}`}
                                        size="lg"
                                    >
                                        Uncaptured
                                    </Button>
                                    <Button
                                        onClick={() => this.onStatusChange([])}
                                        className={`${noStatus ? 'btn-dark' : 'btn-gray-150'}`}
                                        size="lg"
                                    >
                                        All
                                    </Button>
                                </ButtonGroup>
                            </Col>
                            <Col md={6} className="mb-3">
                                <InputGroup>
                                    <Select
                                        options={map(
                                            CHARGE_STATUS,
                                            (value): StatusOptionType => fromChargeStatusToOption(value),
                                        )}
                                        value={this.props.chargeStatuses.map(fromChargeStatusToOption)}
                                        isMulti={true}
                                        components={{ IndicatorSeparator: () => null }}
                                        className="react-select-custom"
                                        onChange={(data) =>
                                            this.onStatusChange(map(data as StatusOptionType[], (el) => el.value))
                                        }
                                    />
                                </InputGroup>
                            </Col>
                        </>
                    )}
                </Row>
                {this.props.onPaymentSourceChange && this.props.paymentSourceId && (
                    <Row>
                        <Col md={6}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <img src={searchIcon} width={'21px'} />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    value={paymentSourceId}
                                    onChange={(e) => this.onPaymentSourceChange(e.target.value)}
                                    placeholder={'Search by Payment Source ID'}
                                    bsSize="lg"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                )}
            </>
        );
    }
}
