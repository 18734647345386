import { SHOW_DEVELOPER_SIGN_UP } from '../constants';
import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { DevelopersState } from '../types';

const unloadedState: DevelopersState = {
    showDeveloperForm: false,
};

export const developersReducer: Reducer<DevelopersState, ApplicationAction> = (
    state = unloadedState,
    action,
): DevelopersState => {
    switch (action.type) {
        case SHOW_DEVELOPER_SIGN_UP:
            return {
                ...state,
                showDeveloperForm: action.payload.data,
            };
        default:
            return state;
    }
};
