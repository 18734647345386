import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import Layout, { LayoutBreadcrumbItem } from '../../components/Layout';
import { Button, Row } from 'reactstrap';
import { ROUTE } from '../../config';
import { RouteComponentProps } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import PlaceholderLoader from '../../components/PlaceholderLoader';
import selectors from '../../store/selectors';
import { CustomerModel, CustomizationType, PayLinkModel } from '../../apis';
import { fetchPayLinkAction, fetchCustomerAction, cleanCustomersAction } from '../../store/actions';
import { ApplicationState } from '../../store';
import { isActionRequesting } from '../../store/selectors/global';
import { bindActionCreators, Dispatch } from 'redux';
import PayLinkStatusBadge from '../../components/payLinks/PayLinkStatusBadge';
import PaylinkQRModal from '../../components/modals/PaylinkQRModal';
import Tooltip from '../../components/Tooltip';
import CustomerDetails from '../../components/customer/CustomerDetails';
import { LinkType } from './CreateStep2';
import PayLinkDocuments from '../../components/payLinks/PayLinkDocuments';
import PayLinkActions from '../../components/payLinks/PayLinkActions';
const chevronRight = '/Text-Link-Arrow.svg';

interface StateProps {
    payLink: PayLinkModel | null;
    customer: CustomerModel | null;
    isRequesting: boolean;
    timezone: string | null;
    customCustomerName: string | null;
}

type OwnProps = RouteComponentProps<{
    payLinkId: string;
}>;

interface DispatchProps {
    fetchPayLinkAction: typeof fetchPayLinkAction;
    fetchCustomerAction: typeof fetchCustomerAction;
    cleanCustomersAction: typeof cleanCustomersAction;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
    showModal: boolean;
    payLinkType: LinkType;
}

export class PayLink extends React.Component<Props, State> {
    state: State = {
        showModal: false,
        payLinkType: 'sms',
    };
    componentDidMount = async () => {
        // Enable loading placeholders again by cleaning
        await this.props.cleanCustomersAction();

        try {
            await this.props.fetchPayLinkAction(this.props.match.params.payLinkId);
        } catch {
            // Error happen, redirect
            this.props.history.push(ROUTE.PAYLINKS);
            return;
        }

        if (this.props.payLink) {
            this.props.fetchCustomerAction(this.props.payLink.customer_id);
        }
    };

    toggleModal = () => this.setState({ showModal: !this.state.showModal });

    showQRCode = () => {
        this.setState({ payLinkType: 'qr' });
        this.toggleModal();
    };

    setPayLinkType = (link: LinkType) => {
        this.setState({ payLinkType: link });
    };

    render() {
        const { payLink, customer, isRequesting, timezone, customCustomerName } = this.props;

        const layoutBreadcrumbItems: LayoutBreadcrumbItem[] = [
            {
                href: ROUTE.PAYLINKS,
                title: 'Paylinks',
            },
        ];

        if (payLink) {
            layoutBreadcrumbItems.push({
                href: selectors.global.payLinkRoute(payLink.id),
                title: payLink.id,
                isId: true,
            });
        }

        const { showModal } = this.state;
        return (
            <Layout breadcrumbItems={layoutBreadcrumbItems}>
                <div className="border-bottom-gray mb-4">
                    <div className="d-flex mb-4">
                        <div className="col-8 px-0">
                            <PlaceholderLoader width="100%" isLoading={isRequesting}>
                                <h1>{payLink && `${payLink.id}`}</h1>
                            </PlaceholderLoader>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between border-bottom-gray pb-3 mb-4">
                        <h2>Paylink details</h2>
                        {payLink && payLink.external_charge_id ? (
                            <Tooltip className="d-flex align-items-center" tooltip="External ID">
                                <p className="id-badge">{payLink.external_charge_id}</p>
                            </Tooltip>
                        ) : null}
                    </div>

                    <Row className="mb-3">
                        <Col md={4}>
                            <div className="detail-card">
                                <h3>Amount</h3>
                                <h2 className="text-success">
                                    <PlaceholderLoader width="100%" isLoading={isRequesting}>
                                        {payLink && payLink.order && (
                                            <>
                                                {payLink.order &&
                                                    selectors.format.formatAmount(payLink.order.total_amount)}
                                            </>
                                        )}
                                    </PlaceholderLoader>
                                </h2>
                            </div>
                        </Col>
                        {payLink && payLink.order && (
                            <Col md={4}>
                                <div className="detail-card">
                                    <div className={`${payLink.order.convenience_fee === 0 && 'opacity-1'}`}>
                                        <h3>Convenience fee</h3>
                                        <PlaceholderLoader width="100%" isLoading={isRequesting}>
                                            {payLink.order.convenience_fee && payLink.order.convenience_fee > 0
                                                ? selectors.format.formatAmount(payLink.order.convenience_fee)
                                                : '—'}
                                        </PlaceholderLoader>
                                    </div>
                                </div>
                            </Col>
                        )}
                    </Row>
                    <Row className="mb-3">
                        <Col md={4}>
                            <div className="detail-card">
                                <div className={`${payLink && !payLink.created_at && 'opacity-1'}`}>
                                    <h3>Created at</h3>
                                    <PlaceholderLoader width="100%" isLoading={isRequesting}>
                                        {(payLink &&
                                            payLink.created_at &&
                                            selectors.format.formatDateTimeSecondsWithYear(
                                                payLink?.created_at,
                                                timezone,
                                            )) ||
                                            '—'}
                                    </PlaceholderLoader>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="detail-card">
                                <div className={`${payLink && !payLink.expires_at && 'opacity-1'}`}>
                                    <h3>Expires at</h3>
                                    <PlaceholderLoader width="100%" isLoading={isRequesting}>
                                        {(payLink &&
                                            payLink.created_at &&
                                            selectors.format.formatDateTimeSecondsWithYear(
                                                payLink?.created_at,
                                                timezone,
                                            )) ||
                                            '—'}
                                    </PlaceholderLoader>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            {payLink && (
                                <div className="detail-card">
                                    <h3>Status</h3>
                                    <PlaceholderLoader width="100%" isLoading={isRequesting}>
                                        {payLink.status && <PayLinkStatusBadge size={'lg'} status={payLink.status} />}
                                    </PlaceholderLoader>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col md={4}>
                            <div className="detail-card">
                                <h3>QR</h3>
                                <div className="d-flex">
                                    <PlaceholderLoader isLoading={isRequesting}>
                                        <Button
                                            className=" font-weight-bold ml-auto"
                                            onClick={this.showQRCode}
                                            size={'lg'}
                                            color={'link'}
                                        >
                                            Show QR Code
                                        </Button>
                                    </PlaceholderLoader>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="detail-card">
                                <h3>URL</h3>
                                <div>
                                    <PlaceholderLoader isLoading={isRequesting}>
                                        <a className="font-weight-bold" href={payLink ? payLink.url : '#'}>
                                            {payLink && payLink.url}
                                        </a>
                                    </PlaceholderLoader>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="detail-card">
                                <h3>Action</h3>
                                <div>
                                    <PlaceholderLoader isLoading={isRequesting}>
                                        <PayLinkActions
                                            payLink={payLink}
                                            customer={customer}
                                            setLink={this.setPayLinkType}
                                            toggleModal={this.toggleModal}
                                            isLoading={isRequesting || !payLink || !customer}
                                        />
                                    </PlaceholderLoader>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className="d-flex mb-5">
                        <div className="ml-auto text-right">
                            <h5>Need help with this paylink?</h5>
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://stronghold.co/happiness-support-contact"
                                className=" text-primary view-link"
                            >
                                <span className="mr-2 font-weight-bolder">Contact support</span>
                                <span>
                                    <img width={'8px'} src={chevronRight} className="link-icon" />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>

                {payLink && <PayLinkDocuments documents={payLink.order.documents} />}

                <CustomerDetails customer={customer} customCustomerName={customCustomerName} />

                <PaylinkQRModal url={payLink && payLink.url} toggle={this.toggleModal} showModal={showModal} />
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state, ownProps) => {
    const payLink = state.payLinks.list.items.find((el) => el.id === ownProps.match.params.payLinkId) || null;
    const customer = state.customer.list.items.find((el) => el.id === (payLink && payLink.customer_id)) || null;
    return {
        payLink,
        customer,
        isRequesting: isActionRequesting(state.global.actions, 'fetch_pay_link'),
        timezone: state.merchant.selected?.timezone || null,
        customCustomerName: selectors.customizations.getCustomization(state, CustomizationType.CustomerName),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchPayLinkAction,
            fetchCustomerAction,
            cleanCustomersAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(PayLink);
