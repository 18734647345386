import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';
import { DashboardUser, ResponseError } from '../apis';
import { addDeveloperUserAction, resetActionResponseErrorAction, setDeveloperSignUpFormAction } from '../store/actions';
import { Button, Col, Label, Row } from 'reactstrap';
import Input from 'reactstrap/lib/Input';
import Loader from '../components/Loader';
import selectors from '../store/selectors';
import { bindActionCreators, Dispatch } from 'redux';
import BackButton from '../components/developers/BackButton';
import { isValidEmail } from '../apis';

interface StateProps {
    isLoading: boolean;
    errorCode: ResponseError | null;
}

interface DispatchProps {
    addDeveloperUserAction: typeof addDeveloperUserAction;
    setDeveloperSignUpFormAction: typeof setDeveloperSignUpFormAction;
    resetActionResponseErrorAction: typeof resetActionResponseErrorAction;
}

type Props = StateProps & DispatchProps;

interface State {
    firstName: string;
    lastName: string;
    email: string;
    title: string;
    agree: boolean;
    formSubmitted: boolean;
}

type Field = 'firstName' | 'lastName' | 'email' | 'title';

class DeveloperSignUp extends React.Component<Props, State> {
    state: State = {
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        agree: false,
        formSubmitted: false,
    };

    onInputChange = async (field: string, label: Field) => {
        await this.setState({ [label]: field } as Pick<State, Field>);
    };

    onSubmit = async () => {
        const { firstName, lastName, email, title } = this.state;

        const dashboardUser: DashboardUser = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            title: title,
        };

        await this.props.addDeveloperUserAction(dashboardUser);
        this.setState({ formSubmitted: true });
    };

    validTitle = (input: string) => {
        return input.trim();
    };

    returnToForm = () => {
        this.props.resetActionResponseErrorAction('add_developer_user');
        this.setState({ formSubmitted: false });
    };

    formatErrorMessage = (errorCode: string) => {
        switch (errorCode) {
            case 'user_email_taken':
                return 'The provided email address has already been registered. Please use a different address.';
            case 'merchant_name_taken':
                return 'The provided merchant name has already been registered. Please select a different name.';
            default:
                return 'An error has occurred during the action. Please contact customer support at happiness@stronghold.co.';
        }
    };

    render() {
        const { isLoading, errorCode } = this.props;
        const { firstName, lastName, email, title, agree, formSubmitted } = this.state;
        const disabled = !firstName || !lastName || !isValidEmail(email) || !this.validTitle(title) || !agree;

        if (formSubmitted) {
            return (
                <div>
                    {errorCode ? (
                        <div>
                            <div>{this.formatErrorMessage(errorCode.code)}</div>
                            <Button
                                className="w-50 text-center justify-content-center m-3"
                                color="primary"
                                onClick={this.returnToForm}
                            >
                                Back to registration
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <h2 className="font-weight-light my-5">Thank you for registering</h2>
                            <p className="w-100 justify-content-center m-3">
                                Please check your inbox for instructions to create your login credentials. Once you gain
                                access into your Merchant Dashboard, you will be able to view your API keys.
                            </p>
                            <BackButton />
                        </div>
                    )}
                </div>
            );
        }

        return (
            <div>
                <h2 className="font-weight-light my-5">Create your Stronghold account</h2>
                <Row className="mb-3">
                    <Col>
                        <div className="mb-1 font-weight-bold float-sm-left">First name</div>
                        <Input
                            type="text"
                            placeholder={'John'}
                            className="bg-light border-2 border-dark"
                            bsSize="lg"
                            value={firstName.toString() || ''}
                            onChange={(e) => this.onInputChange(e.target.value, 'firstName')}
                            data-sh="new-developer-first-name-input"
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <div className="mb-1 font-weight-bold float-sm-left">Last name</div>
                        <Input
                            type="text"
                            placeholder={'Doe'}
                            className="bg-light border-2 border-dark"
                            bsSize="lg"
                            value={lastName.toString() || ''}
                            onChange={(e) => this.onInputChange(e.target.value, 'lastName')}
                            data-sh="new-developer-last-name-input"
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <div className="mb-1 font-weight-bold float-sm-left">Email</div>
                        <Input
                            type="email"
                            placeholder={'john.doe@example.com'}
                            className={`form-control-lg bg-light border-2 border-dark`}
                            value={email.toString() || ''}
                            onChange={(e) => this.onInputChange(e.target.value, 'email')}
                            data-sh="new-developer-email-input"
                        />
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col>
                        <div className="mb-1 font-weight-bold float-sm-left">Title</div>
                        <Input
                            type="text"
                            placeholder={'Title'}
                            className="bg-light border-2 border-dark"
                            bsSize="lg"
                            value={title.toString() || ''}
                            onChange={(e) => this.onInputChange(e.target.value, 'title')}
                            data-sh="new-developer-title-input"
                        />
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col>
                        <div>
                            <Label for="agree">
                                <Input
                                    type="checkbox"
                                    name="agree"
                                    className={`mb-1`}
                                    checked={agree}
                                    onChange={(e) => this.onInputChange(e.target.checked, 'agree')}
                                    data-sh="new-developer-agree-input"
                                />
                                I agree to Stronghold&apos;s{' '}
                                <a href="https://stronghold.co/legal" target="_blank" rel="noopener noreferrer">
                                    Terms and Conditions
                                </a>
                                {' and '}
                                <a
                                    href="https://stronghold.co/privacy-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Privacy Policy
                                </a>
                                .
                            </Label>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BackButton />
                    </Col>
                    <Col>
                        <Button
                            className="w-100 text-center justify-content-center"
                            color="primary"
                            onClick={this.onSubmit}
                            disabled={disabled}
                            data-sh="create-developer-button"
                        >
                            <div>{isLoading ? <Loader color={'white'} size="sm" /> : `Create account`}</div>
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => {
    return {
        isLoading: selectors.global.isActionRequesting(state.global.actions, 'add_developer_user'),
        errorCode: selectors.global.getResponseError(state.global.actions, 'add_developer_user'),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            addDeveloperUserAction,
            setDeveloperSignUpFormAction,
            resetActionResponseErrorAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperSignUp);
