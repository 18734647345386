import axios, { AxiosError } from 'axios';
import { DashboardResponseFailed, ResponseError, ResponseErrorCode, ResponseErrorType } from './response';

export interface DeveloperModel {
    first_name: string;
    last_name: string;
    email: string;
    title: string;
}

export async function createDeveloperUser(developer: DeveloperModel) {
    try {
        await axios.post('/api/developers/register', {
            email: developer.email,
            first_name: developer.first_name,
            last_name: developer.last_name,
            title: developer.title,
        });
    } catch (e) {
        if (axios.isCancel(e)) throw e;
        const error = e as AxiosError<DashboardResponseFailed>;
        const errorData = error && error.response && error.response.data.error;
        if (!errorData) {
            throw new ResponseError(ResponseErrorType.Unknown, ResponseErrorCode.Unknown);
        }
        throw new ResponseError(
            errorData.type as ResponseErrorType,
            errorData.code as ResponseErrorCode,
            errorData.message,
            errorData.property,
        );
    }
}
