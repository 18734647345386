import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { OktaStateProps, ROUTE } from '../config';
import { ApplicationState } from '../store';
import Layout from '../components/Layout';
import TileLink from '../components/launchpad/TileLink';
import { Button, Row } from 'reactstrap';
import { withOktaAuth } from '@okta/okta-react';
import { bindActionCreators, Dispatch } from 'redux';
import { setDeveloperSignUpFormAction } from '../store/actions';
import selectors from '../store/selectors';
import DeveloperSignUp from './DeveloperSignUp';
import { Link } from 'react-router-dom';
import SHQRCode from '../components/SHQRCode';
import Col from 'reactstrap/lib/Col';

interface StateProps {
    isAuthenticated: boolean;
    // isLoading: boolean;
    // earnedPointsYTD: number;
    showDeveloperForm: boolean;
    portalUrl: string;
}

interface DispatchProps {
    // fetchEarnedPointsYTDAction: typeof fetchEarnedPointsYTDAction;
    setDeveloperSignUpFormAction: typeof setDeveloperSignUpFormAction;
}

type Props = StateProps & DispatchProps & OktaStateProps;

const HomeWrapped = withOktaAuth(
    class Home extends React.Component<Props> {
        constructor(props: Props) {
            super(props);
        }

        signIn = async () => {
            await this.props.oktaAuth.signInWithRedirect();
        };
        // componentDidMount = async () => {
        //     this.props.fetchEarnedPointsYTDAction();
        // };
        devSignUp = () => {
            this.props.setDeveloperSignUpFormAction(true);
        };

        render() {
            const { showDeveloperForm, portalUrl } = this.props;
            const isAuthenticated = this.props.authState?.isAuthenticated ?? false;
            if (isAuthenticated) {
                return (
                    <Layout>
                        <h3 className="mb-4">Welcome to the Stronghold merchant dashboard</h3>
                        <Row className="mb-5">
                            <Col md={7} className="d-flex flex-column">
                                <div className="h-100">
                                    <TileLink
                                        bgColor={'grey-150'}
                                        size="l"
                                        link={ROUTE.CUSTOMERS}
                                        title={'Find and manage your customers'}
                                        subTitle={'View Customers'}
                                        fullHeight={true}
                                    />
                                </div>
                                <div>
                                    <Row className="mt-3">
                                        <div className="col-4">
                                            <TileLink
                                                bgColor={'sh-blue'}
                                                size="m"
                                                link={ROUTE.TRANSACTIONS}
                                                title={'Transactions'}
                                                subTitle={'View'}
                                            />
                                        </div>
                                        <div className="col-4">
                                            <TileLink
                                                bgColor={'teal'}
                                                size="m"
                                                link={ROUTE.SETTLEMENTS}
                                                title={'Settlements'}
                                                subTitle={'View'}
                                            />
                                        </div>
                                        <div className="col-4">
                                            <TileLink
                                                bgColor={'cyan'}
                                                size="m"
                                                link={ROUTE.REPORTS}
                                                title={'Reports'}
                                                subTitle={'View'}
                                            />
                                        </div>
                                    </Row>
                                </div>
                            </Col>
                            <Col md={5}>
                                <TileLink
                                    bgColor={'grey-100'}
                                    size="l"
                                    link={ROUTE.IN_STORE}
                                    title={'In Store'}
                                    subTitle={'Learn More'}
                                    newTile={true}
                                >
                                    <div className="text-dark p-3 font-weight-semi-bold">
                                        <span>
                                            Get all the benefits of digital payments with Stronghold for customers
                                            shopping in your store.
                                            <br />
                                            <br />
                                            Avoid the pitfalls of cash and unreliable card solutions.
                                            <br />
                                            <br />
                                            <div className="text-center">
                                                <SHQRCode url={portalUrl} size={225} />
                                            </div>
                                        </span>
                                    </div>
                                </TileLink>
                            </Col>
                        </Row>
                        {/*<Row className="mb-5">*/}
                        {/*<Col md={7}>*/}
                        <div style={{ paddingBottom: '20px' }}>
                            <TileLink
                                bgColor={'grey-150'}
                                size="l"
                                link={ROUTE.PAYLINKS}
                                title={'Create paylinks with Stronghold Direct'}
                                subTitle={'View Paylinks'}
                                fullHeight={true}
                            />
                        </div>

                        {/*</Col>*/}
                        {/*<Col md={5}>*/}
                        {/*    <TileLink*/}
                        {/*        bgColor={'grey-100'}*/}
                        {/*        size="l"*/}
                        {/*        title={'Rewards Program'}*/}
                        {/*        subTitle={'View Rewards'}*/}
                        {/*        link={ROUTE.REWARDS}*/}
                        {/*    >*/}
                        {/*        <div className="h3 mt-3 text-secondary-2 mb-0 p-3">*/}
                        {/*            <PlaceholderLoader width="100%" isLoading={this.props.isLoading}>*/}
                        {/*                {this.props.earnedPointsYTD !== -1*/}
                        {/*                    ? 'You have a total of ' +*/}
                        {/*                      selectors.format.formatNumberNoDecimal(this.props.earnedPointsYTD) +*/}
                        {/*                      ' points!'*/}
                        {/*                    : 'Sign up for our rewards program to start earning points per transaction!'}*/}
                        {/*            </PlaceholderLoader>*/}
                        {/*        </div>*/}
                        {/*    </TileLink>*/}
                        {/*</Col>*/}
                        {/*</Row>*/}
                    </Layout>
                );
            }

            return (
                <Layout>
                    <div className="mw-sm m-auto">
                        <div className="text-center">
                            {showDeveloperForm ? (
                                <DeveloperSignUp />
                            ) : (
                                <div>
                                    <h2 className="font-weight-light my-5">Merchant Dashboard</h2>
                                    <div className="lead mb-5">Please login to continue.</div>
                                    <Button color="primary" id="login" onClick={this.signIn}>
                                        Login
                                    </Button>
                                    <div className="m-2">
                                        {"Don't have an account? "}
                                        <Link to="" className="text-primary" id="dev_sign_up" onClick={this.devSignUp}>
                                            Sign up
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Layout>
            );
        }
    },
);

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => ({
    isAuthenticated: state.authentication.isInitialized,
    // isLoading: selectors.global.isActionRequesting(state.global.actions, 'fetch_earned_points_ytd'),
    // earnedPointsYTD: state.rewards.earnedPointsYTD,
    showDeveloperForm: state.developers.showDeveloperForm,
    portalUrl: selectors.merchant.getPortalUrl(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            // fetchEarnedPointsYTDAction,
            setDeveloperSignUpFormAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(HomeWrapped);
