import * as React from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { resetActionResponseErrorAction, setDeveloperSignUpFormAction } from '../../store/actions';

interface DispatchProps {
    setDeveloperSignUpFormAction: typeof setDeveloperSignUpFormAction;
    resetActionResponseErrorAction: typeof resetActionResponseErrorAction;
}

type Props = DispatchProps;

class BackButton extends React.Component<Props> {
    onSubmit = () => {
        this.props.resetActionResponseErrorAction('add_developer_user');
        this.props.setDeveloperSignUpFormAction(false);
    };

    render() {
        return (
            <Button className="w-100 text-center justify-content-center" color="primary" onClick={this.onSubmit}>
                Back
            </Button>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            setDeveloperSignUpFormAction,
            resetActionResponseErrorAction,
        },
        dispatch,
    );

export default connect(null, mapDispatchToProps)(BackButton);
